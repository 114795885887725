var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"showcase-container"},[(!_vm.isLite)?_c('div',{staticClass:"flight-header",style:({
            '--flight-status-bg-color': _vm.theming.flightStatusBgColor,
            '--flight-status-text-color': _vm.theming.flightStatusTextColor
        })},[_c('span',{staticClass:"header-flight-number"},[_vm._v(" "+_vm._s(_vm.$t('myFlights.flight'))+" "+_vm._s(_vm.flight.flightNumber)+" ")]),_c('span',{staticClass:"flight-status",class:{
                'scheduled': _vm.flight.flightStatus === 'scheduled',
                'gate-opened': _vm.flight.flightStatus === 'scheduled' && _vm.flight.flightInfo.depGate,
                'cancelled': _vm.flight.flightStatus === 'cancelled',
                'active': _vm.flight.flightStatus === 'active',
                'landed': _vm.flight.flightStatus === 'landed'
            },style:({
                '--scheduled-color': _vm.theming.flightStatusScheduled,
                '--gate-opened-color': _vm.theming.flightStatusGateOpened,
                '--cancelled-color': _vm.theming.flightStatusCancelled,
                '--active-color': _vm.theming.flightStatusActive,
                '--landed-color': _vm.theming.flightStatusLanded
            })},[_vm._v(" "+_vm._s(_vm.getFlightStatusLabel(_vm.flight.flightStatus))+" ")]),(_vm.flight.flightInfo.depGate)?_c('div',{staticClass:"flight-gate"},[_c('span',{staticClass:"flight-gate-title"},[_vm._v(" "+_vm._s(_vm.$t('myFlights.gate'))+" ")]),_c('span',{staticClass:"flight-gate-number"},[_vm._v(" "+_vm._s(_vm.flight.flightInfo.depGate)+" ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flight-info"},[_c('div',{staticClass:"location-row"},[_c('div',{staticClass:"location from"},[_vm._v(" "+_vm._s(_vm.flight.fromVenueCode)+" ")]),_c('span',{staticClass:"line-decoration"}),_c('div',{staticClass:"flight-date-container"},[_c('span',{staticClass:"flight-number small"},[_vm._v(" "+_vm._s(_vm.flight.flightNumber)+" ")]),_c('airplane-svg',{staticClass:"airplane-svg"}),_c('span',{staticClass:"flight-date"},[_vm._v(" "+_vm._s(_vm.getDateFromDayOfYear(_vm.flight.dateOfFlight))+" ")])],1),_c('span',{staticClass:"line-decoration"}),_c('div',{staticClass:"location to"},[_vm._v(" "+_vm._s(_vm.flight.toVenueCode)+" ")])]),(!_vm.isLite)?_c('div',{staticClass:"extra-info"},[_c('div',{staticClass:"extra-info-column"},[_c('div',{staticClass:"scheduled-title"},[_vm._v(" "+_vm._s(_vm.$t('myFlights.scheduledDeparture'))+" ")]),_c('div',{staticClass:"scheduled-time"},[_vm._v(" "+_vm._s(_vm.getDepartureTime(_vm.flight.flightDepEstimated))+" ")])]),_c('div',{staticClass:"extra-info-column"},[_c('div',{staticClass:"scheduled-title"},[_vm._v(" "+_vm._s(_vm.$t('myFlights.scheduledArrival'))+" ")]),_c('div',{staticClass:"scheduled-time"},[_vm._v(" "+_vm._s(_vm.getArrivalTime(_vm.flight.flightDepEstimated, _vm.flight.flightInfo.duration, _vm.flight.depDelayed, _vm.flight.arrDelayed))+" ")])])]):_vm._e(),(!_vm.isLite)?_c('div',{staticClass:"points-won"},[_vm._v(" "+_vm._s(_vm.$t('myFlights.congratulationsMessage', { points: 80 }))+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }