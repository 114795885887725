<template>
    <div class="showcase-container">
        <div
            v-if="!isLite"
            class="flight-header"
            :style="{
                '--flight-status-bg-color': theming.flightStatusBgColor,
                '--flight-status-text-color': theming.flightStatusTextColor
            }"
        >
            <span class="header-flight-number">
                {{ $t('myFlights.flight') }} {{ flight.flightNumber }}
            </span>
            <span
                class="flight-status"
                :class="{
                    'scheduled': flight.flightStatus === 'scheduled',
                    'gate-opened': flight.flightStatus === 'scheduled' && flight.flightInfo.depGate,
                    'cancelled': flight.flightStatus === 'cancelled',
                    'active': flight.flightStatus === 'active',
                    'landed': flight.flightStatus === 'landed'
                }"
                :style="{
                    '--scheduled-color': theming.flightStatusScheduled,
                    '--gate-opened-color': theming.flightStatusGateOpened,
                    '--cancelled-color': theming.flightStatusCancelled,
                    '--active-color': theming.flightStatusActive,
                    '--landed-color': theming.flightStatusLanded
                }"
            >
                {{ getFlightStatusLabel(flight.flightStatus) }}
            </span>
            <div v-if="flight.flightInfo.depGate" class="flight-gate">
                <span class="flight-gate-title">
                    {{ $t('myFlights.gate') }}
                </span>
                <span class="flight-gate-number">
                    {{ flight.flightInfo.depGate }}
                </span>
            </div>
        </div>
        <div class="flight-info">
            <div class="location-row">
                <div class="location from">
                    {{ flight.fromVenueCode }}
                </div>
                <span class="line-decoration" />
                <div class="flight-date-container">
                    <span class="flight-number small">
                        {{ flight.flightNumber }}
                    </span>
                    <airplane-svg class="airplane-svg" />
                    <span class="flight-date">
                        {{ getDateFromDayOfYear(flight.dateOfFlight) }}
                    </span>
                </div>
                <span class="line-decoration" />
                <div class="location to">
                    {{ flight.toVenueCode }}
                </div>
            </div>
            <div v-if="!isLite" class="extra-info">
                <div class="extra-info-column">
                    <div class="scheduled-title">
                        {{ $t('myFlights.scheduledDeparture') }}
                    </div>
                    <div class="scheduled-time">
                        {{ getDepartureTime(flight.flightDepEstimated) }}
                    </div>
                </div>
                <div class="extra-info-column">
                    <div class="scheduled-title">
                        {{ $t('myFlights.scheduledArrival') }}
                    </div>
                    <div class="scheduled-time">
                        {{ getArrivalTime(flight.flightDepEstimated, flight.flightInfo.duration, flight.depDelayed, flight.arrDelayed) }}
                    </div>
                </div>
            </div>
            <div v-if="!isLite" class="points-won">
                {{ $t('myFlights.congratulationsMessage', { points: 80 }) }}
            </div>
        </div>
    </div></template>

<script>
    import AirplaneSvg from '@/components/svg/AirplaneSVG'
    import moment from 'moment'
    export default {
        name: 'FlightInfo',
        components: {
            AirplaneSvg
        },
        props: {
            flight: {
                type: Object,
                required: false,
                default: () => ({
                    id: 11,
                    flightSearchId: 3,
                    pnr: 'WSE63H',
                    fromVenueCode: 'JFK',
                    toVenueCode: 'LAX',
                    flightNumber: 'AA123',
                    dateOfFlight: 107,
                    createdAt: '2021-07-01T00:00:00.000Z',
                    flightDepEstimated: '2021-04-17T12:00:00.000Z',
                    flightStatus: 'scheduled',
                    flightInfo: {
                        searchAt: '2021-04-17T12:00:00.000Z',
                        passengerName: 'John Doe',
                        compartmentCode: 'Y',
                        seatNumber: '12A',
                        checkinSequenceNumber: '0112',
                        airlineIata: 'GQ',
                        depDelayed: 5,
                        duration: 190,
                        depTerminal: 'M',
                        depGate: 'B24',
                        arrTerminal: '2',
                        arrGate: null
                    }
                })
            },
            isLite: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        },
        methods: {
            getDateFromDayOfYear(dayOfYear) {
                return moment().dayOfYear(dayOfYear).format('MMM DD')
            },
            getFlightStatusLabel(status) {
                if (status === 'scheduled' && this.flight.flightInfo.depGate) {
                    return this.$t('myFlights.gateOpened')
                }
                switch (status) {
                case 'scheduled':
                    return this.$t('myFlights.scheduled')
                case 'cancelled':
                    return this.$t('myFlights.cancelled')
                case 'active':
                    return this.$t('myFlights.active')
                case 'landed':
                    return this.$t('myFlights.landed')
                default:
                    return '-'
                }
            },
            getDepartureTime(departure, delay = 0) {
                if (!departure) return '-'
                return moment(departure).add(delay, 'minutes').format('HH:mm') || '-'
            },
            getArrivalTime(departure, duration, depDelay = 0, arrDelay = 0) {
                if (!departure) return '-'
                return moment(departure).add(duration, 'minutes').add(depDelay, 'minutes').add(arrDelay, 'minutes').format('HH:mm') || '-'
            }
        }
    }
</script>

<style scoped>
.showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.flight-header {
    padding-top: 0.75rem;
    display: flex;
    flex-direction: column;
    background: var(--flight-status-bg-color);
    border-radius: 5px;
    min-height: 75px;
    width: 100%;
    max-width: 350px;
}

.header-flight-number,
.flight-gate {
    font-size: 0.9375rem;
    color: var(--flight-status-text-color);
}

.flight-number {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--main-content-text);
}

.flight-number.small,
.flight-date {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--main-content-text);
}

.flight-status {
    font-size: 1.25rem;
    text-transform: uppercase;
}

.flight-status.scheduled {
    color: var(--scheduled-color);
}

.flight-status.gate-opened {
    color: var(--gate-opened-color);
}

.flight-status.cancelled {
    color: var(--cancelled-color);
}

.flight-status.active {
    color: var(--active-color);
}

.flight-status.landed {
    color: var(--landed-color);
}

.flight-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: 350px;
    background: rgb(0 0 0 / 40%);
    border-radius: 1rem;
    padding: 0.5rem;
}

.location-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    width: 70%;
}

.location {
    font-size: 1.875rem;
    font-weight: 700;
    color: var(--main-content-text);
    position: relative;
}

.line-decoration {
    display: none;
}

.flight-date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
}

.extra-info {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.extra-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-content-text);
}

.scheduled-title {
    font-weight: 400;
}

.scheduled-time {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--main-content-text);
}

.points-won {
    color: #009ee2;
    font-size: 0.9375rem;
    max-width: 90%;
    font-weight: 400;
}

@media screen and (min-width: 992px) {
    .flight-header {
        padding-top: 0.25rem;
        min-height: 85px;
        max-width: 545px;
    }

    .flight-info {
        max-width: unset;
    }

    .location-row {
        width: 75%;
    }

    .extra-info {
        width: 83%;
    }

    .line-decoration {
        display: block;
        width: 100%;
        height: 1px;
        background: var(--main-content-text);
    }
}
</style>
